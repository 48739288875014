<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="sign-up">
          <h1 class="header">
            Zaloguj
          </h1>
          <SignInForm redirect-on-success="my-account" />
          <p>
            Nie masz konta?
            <router-link :to="{ name: 'sign-up' }">
              Zarejestruj się.
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignInForm from '@/vue/components/auth/sign-in-form.vue';

export default {
  name: 'SignIn',

  components: { SignInForm },
};
</script>
